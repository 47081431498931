import React from 'react'
import { array } from 'prop-types'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import StrapiImageGallery from '@templates/strapi-cms/content-types/ImageGallery/ImageGallery'
import HeadingAndSeeAllLink from '../../@rtg2022/components/molecules/HeadingAndSeeAllLink/HeadingAndSeeAllLink'

export const SwiperWrapper = styled('div')(({ theme }) => ({
  paddingBottom: '20px',
  paddingTop: '18px',
  [theme.breakpoints.up('md')]: {
    marginTop: '60px',
    paddingBottom: '80px',
  },
}))

const StoreSwiper = ({ slides }) => {
  if (!slides || !slides.length) {
    return <Box sx={{ padding: '20px 0' }} />
  }

  const data = {
    Images: slides,
    DisplayScrollbar: true,
    DisplayDesktop: true,
    DisplayMobile: true,
  }

  return (
    <SwiperWrapper>
      <HeadingAndSeeAllLink title="Store Image Gallery" headingStyles={{ fontWeight: 600, marginBottom: '32px' }} />
      <StrapiImageGallery data={data} />
    </SwiperWrapper>
  )
}

export default StoreSwiper

StoreSwiper.propTypes = {
  slides: array,
}
