import React from 'react'
import { object } from 'prop-types'
import { Stack, Typography, styled, css } from '@mui/material'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import getStorePhone from '@helpers/getStorePhone'
import RTGLink from '@components/shared/link'

const StyledPhoneText = styled(Typography)(
  ({ theme }) => css`
    text-decoration-line: none;
    color: ${theme.palette.primary.main};
    margin-left: 13px;
    &:hover {
      text-decoration-line: underline;
    }
  `,
)

const StorePhone = ({ store, storeInfoFromFeed }) => (
  <Stack direction="row">
    <LocalPhoneOutlinedIcon color="primary" />
    <RTGLink
      data={{
        url: `tel:${getStorePhone(storeInfoFromFeed, store)}`,
        title: 'Call store',
        category: 'store page',
        action: 'Call click',
        label: 'Call',
      }}
      className="phone-link"
    >
      <StyledPhoneText>{getStorePhone(storeInfoFromFeed, store)}</StyledPhoneText>
    </RTGLink>
  </Stack>
)

StorePhone.propTypes = {
  store: object,
  storeInfoFromFeed: object,
}

export default StorePhone
