import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { styled } from '@mui/material'

// store template imports
import InStoreAppointment from '@components/store-details-v1/in-store-appointment'
import PaymentOptions from '@components/store-details-v1/payment-options'
import SpecialClosings from '@components/store-details-v1/special-closings'
import StoreDescription from '@components/store-details-v1/store-description'
import StoreDirections from '@components/store-details-v1/store-directions'
import StoreFaqs from '@components/store-details-v1/store-faqs'
import StoreHours from '@components/store-details-v1/store-hours'
import StoreImage from '@components/store-details-v1/store-image'
import StoreLocationInfo from '@components/store-details-v1/store-location-info'
import StoreNeighborhoods from '@components/store-details-v1/store-neighborhoods'
import StoreSeo from '@components/store-details-v1/store-seo'
import StoreTitle from '@components/store-details-v1/store-title'
import { formatReviews } from '@helpers/reviews'
import { scrollTo } from '@helpers/bodyscroll'
import RadarMapStore from '@components/radar-map-store/RadarMapStore'

import {
  StoreDetailsPageColumn1,
  StoreDetailsPageColumn2,
  StoreDetailsPageColumnWrapper,
  StoreDetailsWrapper,
  StyledMapsWrapper,
} from '@components/store-details-v1/styles'

// other component imports
import GoogleMap from '@components/google-map'
import Banner from '@templates/strapi-cms/content-types/Banner'
import Breadcrumb from '@templates/strapi-cms/content-types/Breadcrumbs'

// helper imports
import { expandState } from '@helpers/geo-location'
import { getStoreName, getStoreInfoFromFeed, getStoreSlug } from '@helpers/store-locator'
import { decodeHtml } from '@helpers/string-helper'

// hooks
import usePageAnalytics from '@hooks/usePageAnalytics'
import Accordion from '../../templates/strapi-cms/content-types/Accordion'
import StoreReviewsShort from '../../@rtg2022/components/organisms/Reviews/reviews-short'
import StoreReviews from '../../@rtg2022/components/organisms/Reviews/reviews'

const storeShape = PropTypes.shape({
  strapiStore: PropTypes.shape({
    Banner: PropTypes.object,
    City: PropTypes.string,
    Description: PropTypes.object,
    Directions: PropTypes.object,
    FAQs: PropTypes.object,
    NearbyNeighborhoods: PropTypes.array,
    SpecialClosings: PropTypes.object,
    State: PropTypes.string,
    StoreImage: PropTypes.object,
    StoreName: PropTypes.string,
    StoreNumber: PropTypes.number,
    StoreType: PropTypes.string,
    SEO: PropTypes.object,
  }),
})

const breadcrumbsStyles = {
  paddingBottom: '16px',
}

const createCrumb = (label, url) => ({
  category: label,
  id: url,
  url,
  label,
})

const generateStoreCrumbs = store => [
  createCrumb('Find a Showroom', '/stores'),
  ...(store?.State
    ? [
        createCrumb(
          `Find a Showroom - ${expandState(store.State)}`,
          `/stores/${expandState(store.State)
            .toLowerCase()
            .replace(' ', '-')}`,
        ),
      ]
    : []),
  createCrumb(
    decodeHtml(`${store.City}, ${store.State}${store.StoreName ? ` (${store.StoreName})` : ''}`),
    getStoreSlug(store),
  ),
]

const StyledStoreLocationInfo = styled(StoreLocationInfo)({
  marginBottom: '0!important',
})

const StyledStoreReviewsShort = styled(StoreReviewsShort)(({ theme }) => ({
  marginTop: '15px!important',
  '.MuiRating-root': {
    svg: { fill: `${theme.palette.secondary.lighter} !important` },
    '.MuiRating-iconFilled': {
      svg: { fill: `${theme.palette.primary.main} !important` },
    },
  },
}))

const StoreV1 = ({ data, reviews, storeInfo }) => {
  const store = data
  const storeInfoFromFeed = getStoreInfoFromFeed(store?.StoreNumber)
  const breadCrumbs = useMemo(() => generateStoreCrumbs(store), [store])
  const [width, setWidth] = useState()

  const formattedReviews = useMemo(() => formatReviews({ reviewsData: reviews }), [reviews])

  usePageAnalytics({
    type: 'store',
    title: getStoreName(store),
  })

  useEffect(() => {
    window.addEventListener('resize', windowResized)
    setWidth(window.innerWidth)
    return () => window.removeEventListener('resize', windowResized)
  }, [store])

  const getStoreTypeTitle = () => {
    let storeTypeTitle = `${store.City}, ${store.State}`
    if (store.StoreType === 'Showroom' || store.StoreType === 'Express') {
      storeTypeTitle = `${storeTypeTitle} Furniture & Mattress Store`
    } else if (store.StoreType === 'Kids') {
      storeTypeTitle = `${storeTypeTitle} Kids Furniture & Mattress Store`
    } else if (store.StoreType === 'Outlet') {
      storeTypeTitle = `${storeTypeTitle} Affordable Furniture Outlet Store`
    } else if (store.StoreType === 'Patio') {
      storeTypeTitle = `${storeTypeTitle} Patio Furniture`
    }
    return storeTypeTitle
  }

  const renderBanner = (banner, isMobile) => {
    if (banner) {
      return <Banner data={banner} isMobile={isMobile} />
    }
    return null
  }

  const windowResized = () => {
    setTimeout(() => {
      setWidth(window.innerWidth)
    }, 100)
  }

  const handleReviewsClick = () => {
    const reviewsAccordion = document.getElementById('customer-store-reviews-header')
    if (!reviewsAccordion?.classList?.contains?.('Mui-expanded')) {
      reviewsAccordion?.click?.()
    }
    scrollTo('customer-store-reviews-header')
  }

  if (!store) return null
  const storeTypeTitle = getStoreTypeTitle()
  return (
    <>
      <Helmet title={storeTypeTitle} />
      {width <= 1024 ? (
        <StoreDetailsWrapper>
          <StoreSeo seo={store.SEO.SEO} storeName={store.StoreName} storeTypeTitle={storeTypeTitle} />
          <StoreDetailsPageColumn1>
            <div className="stores-breadcrumb" style={breadcrumbsStyles}>
              <Breadcrumb storeBreadcrumbs={breadCrumbs} />
            </div>
            <StoreTitle storeTypeTitle={storeTypeTitle} />
            <SpecialClosings specialClosings={store.SpecialClosings} />
            {renderBanner(store.Banner, true)}
            <StyledMapsWrapper className="wrapper">
              <RadarMapStore variant="radar-map-store-desktop-v1" store={store} active />
            </StyledMapsWrapper>
            <StoreLocationInfo store={store} storeInfoFromFeed={storeInfoFromFeed} storeTypeTitle={storeTypeTitle} />
            <StyledStoreReviewsShort
              reviewsData={formattedReviews}
              onReviewsClick={handleReviewsClick}
              overallScore={storeInfo?.storeOverallScore}
            />
            <InStoreAppointment store={store} storeInfo={storeInfo} />
            <StoreImage storeImage={store.StoreImage} storeTypeTitle={storeTypeTitle} />
            <StoreHours storeInfoFromFeed={storeInfoFromFeed} />
            <StoreDescription description={store.Description} />
            <StoreFaqs faqs={store.FAQs} />
            <StoreDirections directions={store.Directions} />
            <StoreNeighborhoods neighborhoodsNearby={store.NearbyNeighborhoods} />
            <PaymentOptions storeType={store.StoreType} />
            {reviews?.length > 0 && (
              <Accordion
                isReviews
                id="store-reviews-accordion"
                gridWrapper={false}
                data={{ Heading: 'Customer Store Reviews', id: 'customer-store-reviews', Hidden: true }}
              >
                <StoreReviews reviewsData={formattedReviews} overallScore={storeInfo?.storeOverallScore} />
              </Accordion>
            )}
          </StoreDetailsPageColumn1>
        </StoreDetailsWrapper>
      ) : (
        <>
          <StoreSeo seo={store.SEO.SEO} storeName={store.StoreName} storeTypeTitle={storeTypeTitle} />
          <div className="stores-breadcrumb" style={breadcrumbsStyles}>
            <Breadcrumb storeBreadcrumbs={breadCrumbs} />
          </div>
          <StoreDetailsWrapper>
            <StoreTitle storeTypeTitle={storeTypeTitle} />
            {renderBanner(store.Banner, false)}
            <StoreDetailsPageColumnWrapper>
              <StoreDetailsPageColumn1>
                <StoreImage storeImage={store.StoreImage} storeTypeTitle={storeTypeTitle} />
                <StoreHours storeInfoFromFeed={storeInfoFromFeed} />
                <InStoreAppointment store={store} storeInfo={storeInfo} />
                <StoreNeighborhoods neighborhoodsNearby={store.NearbyNeighborhoods} />
                <StoreDirections directions={store.Directions} />
                <PaymentOptions storeType={store.StoreType} />
              </StoreDetailsPageColumn1>
              <StoreDetailsPageColumn2>
                <SpecialClosings specialClosings={store.SpecialClosings} />
                <StoreLocationInfo
                  store={store}
                  storeInfoFromFeed={storeInfoFromFeed}
                  storeTypeTitle={storeTypeTitle}
                />
                <StyledStoreReviewsShort
                  reviewsData={formattedReviews}
                  onReviewsClick={handleReviewsClick}
                  overallScore={storeInfo?.storeOverallScore}
                />
                <StoreDescription description={store.Description} />
                <StoreFaqs faqs={store.FAQs} />
                <StyledMapsWrapper className="wrapper">
                  <RadarMapStore variant="radar-map-store-mobile-v1" store={store} active />
                </StyledMapsWrapper>
                {reviews?.length > 0 && (
                  <Accordion
                    isReviews
                    id="store-reviews-accordion"
                    gridWrapper={false}
                    data={{ Heading: 'Customer Store Reviews', id: 'customer-store-reviews', Hidden: true }}
                  >
                    <StoreReviews reviewsData={formattedReviews} overallScore={storeInfo?.storeOverallScore} />
                  </Accordion>
                )}
              </StoreDetailsPageColumn2>
            </StoreDetailsPageColumnWrapper>
          </StoreDetailsWrapper>
        </>
      )}
    </>
  )
}

StoreV1.propTypes = {
  data: storeShape,
  reviews: PropTypes.array,
  storeInfo: PropTypes.shape({
    storeTimeZone: PropTypes.string,
    storeTemporarilyClosed: PropTypes.bool,
    storeOverallScore: PropTypes.string,
  }),
}

export default StoreV1
