import React from 'react'
import { arrayOf, string } from 'prop-types'
import { Stack, Chip, Divider, styled, css } from '@mui/material'

const AvailableHereChip = styled(Chip)(
  ({ theme }) => css`
    color: ${theme.palette.primary.dark};
    background: #f5f5f5;
    border-radius: 4px;
    padding: 5px 10px;
    font-weight: 500;
    font-size: 19px;
    width: calc(50% - 0.5rem);
    ${theme.breakpoints.up('md')} {
      width: auto;
      margin-right: 0.5rem;
    }
  `,
)

const AvailableHere = ({ availableItems }) => {
  if (availableItems?.length > 0) {
    return (
      <Stack
        direction="row"
        flexWrap="wrap"
        margin="0.5rem 0 2rem 0"
        gap="0.5rem"
        divider={
          <Divider
            orientation="vertical"
            flexItem
            sx={{ width: 'auto', marginRight: '0.5rem', display: { xs: 'none', md: 'block' } }}
          />
        }
      >
        {availableItems.map(item => (
          <AvailableHereChip label={item} key={`available-item-${item}`} />
        ))}
      </Stack>
    )
  }
  return null
}

AvailableHere.propTypes = {
  availableItems: arrayOf(string),
}

export default AvailableHere
