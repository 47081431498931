import React, { useState } from 'react'
import { object } from 'prop-types'
import { styled, css, useMediaQuery, Typography } from '@mui/material'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'

const StyledDescription = styled('div')(
  ({ theme, isMobile, isExpanded }) => css`
    font-size: 19px;
    line-height: 24px;
    overflow: hidden;
    max-height: 192px;
    margin-bottom: 0.5rem;
    ${isMobile && isExpanded && 'max-height: none;'}

    ${theme.breakpoints.up('md')} {
      max-height: none;
    }
    // We need to override the default styles for the p tag
    p {
      font-size: 1.2rem;
      line-height: 24px;
    }

    h1 {
      padding: 0;
      line-height: 1.5;
      font-size: 1.2rem;
      font-weight: 500;
      color: ${theme.palette.secondary.dark};
      margin-bottom: 0.5rem;
      text-align: left;
    }
  `,
)

const StoreDescription = ({ description }) => {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false)
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
  if (description) {
    return (
      <div>
        <StyledDescription
          isMobile={isMobile}
          isExpanded={isDescriptionExpanded}
          dangerouslySetInnerHTML={{ __html: description.data?.childMarkdownRemark.html }}
        />
        {isMobile && !isDescriptionExpanded && (
          <Typography
            variant="span"
            onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
            color="primary.main"
            sx={{ cursor: 'pointer', verticalAlign: 'middle', textDecoration: 'underline' }}
          >
            Read More <ArrowDropDownOutlinedIcon sx={{ verticalAlign: 'bottom' }} />
          </Typography>
        )}
      </div>
    )
  }
  return null
}

StoreDescription.propTypes = {
  description: object,
}

export default StoreDescription
