import React, { useState, useEffect } from 'react'
import { objectOf, any, number } from 'prop-types'
import { Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react'
import { getStoreHours, getStoreName, getStoreInfoFromFeed } from '@helpers/store-locator'
import getStorePhone from '@helpers/getStorePhone'
import '@assets/css/pages/store-location.sass'
import StoreInfo from '../store-locator/store-info'
import StyledGoogleMap from './styles'
import Spinner from './spinner'

const GoogleMap = ({ pageContext, store, google, zoom }) => {
  const [showingInfoWindow, setShowingInfoWindow] = useState(false)
  const [activeMarker, setActiveMarker] = useState({})
  const [selectedMarker, setSelectedMarker] = useState({})
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  const handleLocationClick = (props, marker) => {
    const storeInfoFromFeed = getStoreInfoFromFeed(store.StoreNumber)
    const fullMarker = {
      title: store.Address1,
      name: getStoreName(store),
      lat: store.Location.latLng.lat,
      lng: store.Location.latLng.lng,
      city: store.City,
      state: store.State,
      storeNumber: store.StoreNumber || store.storeNumber,
      StoreNumber: store.StoreNumber || store.storeNumber,
      zip: store.Zip,
      phone: getStorePhone(storeInfoFromFeed, store),
      hours: storeInfoFromFeed ? getStoreHours(storeInfoFromFeed.store_hours) : '',
    }

    setSelectedMarker(fullMarker)
    setActiveMarker(marker)
    setShowingInfoWindow(true)
  }

  const onMapClicked = () => {
    if (showingInfoWindow) {
      setShowingInfoWindow(false)
      setActiveMarker(null)
    }
  }

  if (!isLoaded) {
    return null
  }

  return (
    <StyledGoogleMap
      initialCenter={{ lat: store.Location.latLng.lat, lng: store.Location.latLng.lng }}
      center={{ lat: store.Location.latLng.lat, lng: store.Location.latLng.lng }}
      className="map"
      google={google}
      zoom={zoom}
      onClick={onMapClicked}
      mapTypeControl={false}
      scaleControl={false}
      streetViewControl={false}
      rotateControl={false}
      fullscreenControl={false}
    >
      <Marker
        position={{ lat: store.Location.latLng.lat, lng: store.Location.latLng.lng }}
        title={`${store.City}${store.StoreName ? ` - ${store.StoreName}` : ''} ${store.StoreType}`}
        icon={{
          url: 'https://maps.google.com/mapfiles/marker.png',
        }}
        onClick={handleLocationClick}
      />
      <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
        <StoreInfo selectedMarker={selectedMarker} />
      </InfoWindow>
    </StyledGoogleMap>
  )
}

GoogleMap.propTypes = {
  pageContext: objectOf(any),
  store: objectOf(any),
  google: objectOf(any),
  zoom: number,
}

export default GoogleApiWrapper({
  apiKey: `${process.env.GATSBY_GOOGLE_BROWSER_API_KEY}`,
  LoadingContainer: Spinner,
})(GoogleMap)
