import React from 'react'
import Accordion from '@templates/strapi-cms/content-types/Accordion'
import { Link, Typography, Stack, styled, css } from '@mui/material'

const StyledStoreQuestions = styled(Stack)(
  ({ theme }) => css`
    margin: 0.5rem 0;
    font-weight: 500;
    font-size: 14px;
    ${theme.breakpoints.up('md')} {
      font-size: 16px;
    }
    ul {
      list-style: disc;
      padding: 0.5rem 1.5rem;
    }
    a,
    .liveChatButton {
      color: ${theme.palette.primary.main};
      text-decoration: none;
      font-size: 14px;
      font-weight: 500;
      ${theme.breakpoints.up('md')} {
        font-size: 16px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  `,
)

const StoreQuestions = () => {
  const onChatRequest = () => {
    // eslint-disable-next-line no-undef
    if (insideFrontInterface && insideFrontInterface.chatReady) {
      // eslint-disable-next-line no-undef
      insideFrontInterface.openChatPane()
    } else {
      console.warn('Chat not ready')
    }
  }
  return (
    <StyledStoreQuestions>
      <Typography variant="body1" fontWeight="500">
        Have questions? Chat with an expert
      </Typography>
      <ul>
        <li>
          Call <Link href="tel:1-888-709-5380">1-888-709-5380</Link>
        </li>
        <li>Text ROOMS (76667) - Keyword SALES</li>
        <li>
          <button type="button" className="liveChatButton" onClick={onChatRequest}>
            Live Chat
          </button>
        </li>
      </ul>
    </StyledStoreQuestions>
  )
}

export default StoreQuestions
