import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, styled, Typography } from '@mui/material'

const StyledContainer = styled(Box)(({ theme }) => ({ flex: 1, position: 'relative' }))

const StyledItemContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  opacity: 1,
  position: 'absolute',
  transition: 'all 0.8s',
  '& > *': {
    height: '100% !important',
  },
  '&.hidden': {
    opacity: 0,
    pointerEvents: 'none',
  },
}))

const SwitchContainer = ({ currentDisplay, switchItems }) => (
  <StyledContainer>
    {switchItems?.map((switchItem, index) => {
      const ItemComponent = switchItem?.Component

      const props = {
        ...(switchItem?.key === 'map' && { enableDirections: true }),
      }
      return (
        <StyledItemContainer key={switchItem?.key} className={` ${currentDisplay !== index ? 'hidden' : ''}`}>
          <ItemComponent active={currentDisplay === index} {...props} />
        </StyledItemContainer>
      )
    })}
  </StyledContainer>
)

SwitchContainer.propTypes = {
  switchItems: PropTypes.array,
  currentDisplay: PropTypes.number,
}

export default SwitchContainer
