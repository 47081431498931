import React from 'react'
import { object } from 'prop-types'
import { SectionWrapper } from '@components/store-details-v1/styles'

const StoreDirections = ({ directions }) => {
  if (directions?.childMarkdownRemark) {
    return (
      <SectionWrapper>
        <span dangerouslySetInnerHTML={{ __html: directions.data?.childMarkdownRemark.html }} />
      </SectionWrapper>
    )
  }
  return null
}

StoreDirections.propTypes = {
  directions: object,
}

export default StoreDirections
