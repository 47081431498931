import React from 'react'
import { object } from 'prop-types'
import Accordion from '@templates/strapi-cms/content-types/Accordion'
import { styled, css } from '@mui/material'

const StyledWrapper = styled('div')(css`
  padding: 0 10px;
`)

const StoreDirections = ({ directions }) => {
  if (directions.data?.childMarkdownRemark) {
    return (
      <Accordion gridWrapper={false} data={{ Heading: 'Driving Directions', id: 'store-directions', Hidden: true }}>
        <StyledWrapper dangerouslySetInnerHTML={{ __html: directions.data?.childMarkdownRemark.html }} />
      </Accordion>
    )
  }
  return null
}

StoreDirections.propTypes = {
  directions: object,
}

export default StoreDirections
