import React, { useState, useEffect } from 'react'
import { Box, styled, IconButton, Grid } from '@mui/material'
import { Directions, Room } from '@mui/icons-material'
import { bool, string } from 'prop-types'
import Radar from 'radar-sdk-js'
import { NavigationControl } from 'maplibre-gl'
import RTGLink from '@components/shared/link'
import 'radar-sdk-js/dist/radar.css'

const MapContainer = styled('div')(({ theme, hideNav }) => ({
  position: 'relative',
  height: '100%',
  width: '100%',
  '& .maplibregl-ctrl-bottom-right button': {
    display: 'none',
  },
  '& .maplibregl-ctrl-top-right': {
    display: hideNav ? 'none' : 'block',
    paddingRight: '10px',
    paddingTop: '10px',
  },
  [theme.breakpoints.down('md')]: {
    '& .maplibregl-ctrl-top-right': {
      paddingRight: 0,
      paddingTop: 0,
    },
  },
}))

const StyledDirectionsIcon = styled(Directions)(({ theme }) => ({
  color: theme.palette.primary.dark,
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  left: 20,
  top: 20,
  backgroundColor: theme.palette.common.white,
  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  width: '48px',
  height: '48px',
  '&:hover': { backgroundColor: '#F0F0F0' },
  [theme.breakpoints.down('md')]: {
    left: 10,
    top: 10,
  },
}))

const StyledPinWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgb(253, 247, 226)',
}))

const StyledPinIcon = styled(Room)(({ theme }) => ({
  color: theme.palette.error.main,
  width: '60px',
  height: '60px',
}))

const addStoreMarkerToMap = (map, latLng) => {
  if (!map) return
  const { lat, lng } = latLng

  const marker = Radar.ui
    .marker({
      color: '#e11f21',
    })
    .setLngLat([lng, lat])

  marker.addTo(map)
}

export const RadarFlipIcon = () => (
  <StyledPinWrapper>
    <StyledPinIcon />
  </StyledPinWrapper>
)

const RadarMapStore = ({ variant, store, hideNav, hideDirections, active }) => {
  const [isInitialized, setIsInitialized] = useState(false)

  const { latLng } = store?.Location ?? {}
  // This loads the map and sets the initial center position
  useEffect(() => {
    if (isInitialized || !latLng.lat || !latLng.lng || !active) return
    Radar.initialize(process.env.GATSBY_RADAR_MAPS_KEY ?? '')

    // eslint-disable-next-line new-cap
    const initialMap = Radar.ui.map({
      container: variant,
      style: 'radar-default-v1',
      center: [latLng.lng, latLng.lat],
      zoom: 14,
    })
    const nav = new NavigationControl({ showCompass: false })
    initialMap.addControl(nav, 'top-right')
    addStoreMarkerToMap(initialMap, latLng)
    setIsInitialized(true)
  }, [isInitialized, variant, latLng, active])

  return (
    <>
      <MapContainer id="map-container" hideNav={hideNav}>
        <Box id={variant} position="absolute" width="100%" height="100%" />
        {!hideDirections && (
          <RTGLink
            data={{
              url: `https://www.google.com/maps/dir/?api=1&origin=Current+Location&destination=${latLng?.lat},${latLng?.lng}`,
              title: `Directions to ${store?.name}`,
              category: 'store locator',
              action: 'Directions store map click',
              label: 'Directions to store',
            }}
            target="_blank"
            className="store-address-link"
          >
            <StyledIconButton>
              <StyledDirectionsIcon />
            </StyledIconButton>
          </RTGLink>
        )}
      </MapContainer>
    </>
  )
}

export default RadarMapStore

RadarMapStore.propTypes = {
  variant: string,
  store: string,
  hideNav: bool,
  hideDirections: bool,
  active: bool,
}
