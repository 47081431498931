import React, { useState, useEffect } from 'react'
import { object, shape, string, bool } from 'prop-types'
import { navigate } from 'gatsby'
import InStoreScheduleModal from '@shared/modals/InStoreSchedule'
import { getURLParam } from '@helpers/general'
import { cancelStoreAppointment } from '@services/stores'
import { InStoreAppointmentWrapper } from '@components/store-details-v1/styles'

const InStoreAppointment = ({ store, storeInfo }) => {
  const { InStoreAppointments, State, StoreNumber, StoreType } = store
  const [showScheduleModal, setShowScheduleModal] = useState(false)
  const [isReschedule, setIsReschedule] = useState(false)

  useEffect(() => {
    const utm_campaign = getURLParam('utm_campaign')
    if (utm_campaign === 'cancel-reschedule') {
      cancelAppointment()
    }
  }, [])

  const onOpenModal = storeId => {
    window.dataLayer.push({ event: 'schedule_visit_start', storeId })
    setShowScheduleModal(true)
  }

  const changeLocation = () => navigate('/stores')

  const cancelAppointment = () => {
    setShowScheduleModal(true)
    setIsReschedule(true)
    const appointmentId = getURLParam('appointmentId')
    const storeId = getURLParam('storeId')
    const appointmentTime = getURLParam('appointmentTime')
    if (typeof window !== 'undefined') {
      window.dataLayer.push({
        event: 'schedule_visit_cancel',
        storeId,
        appointmentTime,
      })
    }
    cancelStoreAppointment(appointmentId)
  }

  if (StoreType !== 'Outlet' && State !== 'PR' && InStoreAppointments) {
    return (
      <InStoreAppointmentWrapper>
        <span
          role="button"
          tabIndex="0"
          onClick={() => onOpenModal(StoreNumber)}
          onKeyDown={event => {
            if (event.keyCode === 13) setShowScheduleModal(true)
          }}
        >
          Schedule a Showroom Visit
        </span>
        <InStoreScheduleModal
          shouldShowModal={showScheduleModal}
          store={store}
          closeModal={() => setShowScheduleModal(false)}
          isReschedule={isReschedule}
          changeLocation={changeLocation}
          storeInfo={storeInfo}
        />
      </InStoreAppointmentWrapper>
    )
  }
  return null
}

InStoreAppointment.propTypes = {
  store: object,
  storeInfo: shape({
    storeTimeZone: string,
    storeTemporarilyClosed: bool,
    storeOverallScore: string,
  }),
}

export default InStoreAppointment
