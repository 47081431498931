import React from 'react'
import { array } from 'prop-types'
import { styled, css } from '@mui/material'
import Accordion from '@templates/strapi-cms/content-types/Accordion'

const MultipleColumn = styled('ul')(
  ({ theme }) => css`
    column-count: 2;
    padding: 0 1rem;

    ${theme.breakpoints.up('md')} {
      padding: 0 3rem;
      column-count: 4;
    }
  `,
)

const StoreNeighborhoods = ({ neighborhoodsNearby }) => {
  if (neighborhoodsNearby && neighborhoodsNearby.length > 0) {
    return (
      <Accordion
        gridWrapper={false}
        data={{ Heading: 'Neighborhoods Nearby', id: 'store-neighborhoods', Hidden: true }}
      >
        <MultipleColumn>
          {neighborhoodsNearby.map(({ Name: hood }) => (
            <li key={hood} className="hood">
              {hood}
            </li>
          ))}
        </MultipleColumn>
      </Accordion>
    )
  }
  return null
}

StoreNeighborhoods.propTypes = {
  neighborhoodsNearby: array,
}

export default StoreNeighborhoods
