import React from 'react'
import { array, func, number, string } from 'prop-types'
import { Box, css, styled, Typography } from '@mui/material'

const StyledContainer = styled(Box)(({ theme }) => ({
  border: `1.5px solid ${theme.palette.common.white}`,
  borderRadius: 4,
  width: 100,
  height: 100,
  cursor: 'pointer',
  perspective: '1000px',
  textAlign: 'center',
  transition: 'transform 0.8s',
  transformStyle: 'preserve-3d',
  '&.flip': {
    transform: 'rotateY(180deg)',
  },
  '&:hover': {
    boxShadow: '0px 10px 20px 2px rgba(255, 255, 255, 0.2)',
  },
  [theme.breakpoints.down('md')]: {
    width: 90,
    height: 80,
  },
}))

const StyledItemContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  '-webkit-backface-visibility': 'hidden',
  backfaceVisibility: 'hidden',

  '& > *': {
    pointerEvents: 'none',
    height: '100% !important',
  },
  '&.hidden': {
    display: 'none',
  },
  '&.flip-front': {},
  '&.flip-back': { transform: 'rotateY(180deg)' },
  '& .maplibregl-ctrl-attrib-inner, & .maplibregl-control-container': {
    display: 'none',
  },
}))

const FlipContainer = ({ className, setCurrentDisplay, currentDisplay, switchItems }) => {
  const displayIndex = currentDisplay >= switchItems?.length ? 0 : currentDisplay

  return (
    <StyledContainer
      className={`${className} ${displayIndex % 2 !== 0 ? 'flip' : ''}`}
      onClick={() => setCurrentDisplay(displayIndex)}
    >
      {switchItems?.map((switchItem, index) => {
        const ItemComponent = switchItem?.CustomFlipComponent ?? switchItem?.Component
        const props = {
          ...(switchItem?.key === 'map' && { enableDirections: false }),
        }
        return (
          <StyledItemContainer key={switchItem?.key} className={` ${index % 2 === 0 ? 'flip-front' : 'flip-back'}`}>
            <ItemComponent {...props} />
          </StyledItemContainer>
        )
      })}
    </StyledContainer>
  )
}

FlipContainer.propTypes = {
  switchItems: array,
  setCurrentDisplay: func,
  currentDisplay: number,
  className: string,
}

export default FlipContainer
