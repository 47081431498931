import React from 'react'
import { Grid, Stack, Link, Typography, styled, css } from '@mui/material'

const StoreFeatureStyles = styled(Grid)(
  ({ theme }) => css`
    background-color: rgba(0, 83, 160, 0.04);
    padding: 2rem 0;
    margin-top: 2rem;
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      text-decoration: none;
      margin-top: 1.5rem;

      &:hover {
        text-decoration: underline;
      }

      ${theme.breakpoints.up('md')} {
        margin-top: 0;
      }
    }

    h2 {
      font-size: 14px;
      margin: 0 1em;
      ${theme.breakpoints.up('md')} {
        font-size: 23px;
      }
    }

    .StoreFeature {
      text-align: center;
      &__Icon {
        margin-bottom: 1.5rem;
        width: 100px;
        height: 100px;
        background-color: rgba(0, 83, 160, 0.08);
        border-radius: 50%;
        padding: 25px;
        ${theme.breakpoints.up('md')} {
          width: 200px;
          height: 200px;
          padding: 50px;
        }
        &:hover {
          background-color: rgba(0, 83, 160, 0.12);
        }
      }
    }
  `,
)

const StoreFeatures = () => (
  <StoreFeatureStyles container>
    <Grid item xs={6} md={3}>
      <Link href="/financing" className="StoreFeature">
        <div className="StoreFeature__Icon">
          <svg viewBox="0 0 100 100" width="100%" alt="" role="presentation" aria-hidden="true">
            <rect width="100" height="100" fill="none" />
            <g>
              <path
                d="M.05,54.16v44.42H23.98v-5.93l.1,.04c6.26,2.08,15.2,3.93,24.04,7.09l.66,.22,.65-.15c2.78-.65,10.27-2.96,19.19-5.82,8.93-2.87,19.11-6.26,26.68-8.87,3.62-1.25,5.57-5.31,4.24-8.91-1.4-3.68-5.46-5.34-8.92-4.28-2.88,.88-16.39,5.06-25.27,7.68,.08-2.53,.75-5.27-1.31-6.88l-.11-.11-.14-.06c-9.97-5.95-10.82-6.72-24.84-14.17l-.66-.32-.72,.07c-4.82,.52-13.01,1.87-13.61,1.97v-5.99H.05Zm4.74,4.74h14.48v34.94H4.79V58.9Zm32.59,4.09c12.81,6.82,14.28,7.86,23.75,13.51,1.54,1.2,1.94,2.69,1.02,4.02-.5,.72-1.02,1-1.74,1.12-.72,.13-1.72-.04-2.86-.54-4.26-1.97-6.58-3.23-11.3-5.87l-2.06-1.16-2.31,4.13,2.06,1.16c4.74,2.66,7.25,4.05,11.66,6.08h.04c1.8,.8,3.7,1.19,5.57,.87,.78-.13,1.28-.92,1.99-1.31l.07,.21,2.28-.65c8.88-2.6,23.51-7.13,26.47-8.04v-.04c1.12-.34,2.63,.22,3.08,1.41,.42,1.19-.13,2.34-1.34,2.76-7.52,2.6-17.68,5.97-26.58,8.83-8.67,2.79-16.01,5.01-18.32,5.57-8.9-3.09-17.74-5.04-23.28-6.88l-1.59-.51v-22.74l.4-.06s8.18-1.32,13-1.89h0v.02Z"
                fill="#003566"
              />
              <path
                d="M69.63,0c-16.18,0-29.36,13.18-29.36,29.36s13.18,29.33,29.36,29.33,29.33-13.15,29.33-29.33S85.81,0,69.63,0Zm0,4.74c13.62,0,24.59,11,24.59,24.62s-10.97,24.58-24.59,24.58-24.62-10.97-24.62-24.58S56.02,4.74,69.63,4.74Z"
                fill="#003566"
              />
              <path
                d="M67.23,13.51v4.11h-6.45v14.1h12.94v4.62h-12.94v4.74h6.45v4.09h4.74v-4.09h6.49v-14.1h-12.94v-4.62h12.94v-4.74h-6.49v-4.11h-4.74Z"
                fill="#003566"
              />
            </g>
          </svg>
        </div>
        <Typography variant="h2" fontSize="1.5rem" color="primary.dark" fontWeight="600">
          Financing Plans
        </Typography>
      </Link>
    </Grid>
    <Grid item xs={6} md={3}>
      <Link href="/customer-service/delivery-shipping" className="StoreFeature">
        <div className="StoreFeature__Icon">
          <svg viewBox="0 0 100.01 100" alt="" role="presentation" aria-hidden="true">
            <rect x="0" width="100" height="100" fill="none" />
            <g>
              <path
                d="M98.35,48.95l-6.03-2.81-10.27-12.47c-.55-.64-1.36-1.02-2.2-1.02h-7.36v-10.13c0-1.58-1.28-2.87-2.87-2.87H3.12c-1.08-.09-2.12,.43-2.68,1.35-.57,.93-.57,2.09,0,3.01,.57,.92,1.61,1.45,2.69,1.35h63.64v25.86c0,.76,.29,1.49,.83,2.03,.53,.54,1.26,.84,2.02,.84h17.37c.64,0,1.26-.22,1.77-.6l2.08-1.68,3.39,1.56v11.6h-5.55c-1.77-3.74-5.54-6.12-9.67-6.12s-7.9,2.38-9.67,6.12h-30.36c-1.77-3.74-5.54-6.12-9.67-6.12s-7.9,2.38-9.67,6.12H10.13c-1.48,.13-2.62,1.37-2.62,2.86s1.14,2.73,2.62,2.86h8.5c.59,5.44,5.19,9.57,10.66,9.57s10.07-4.13,10.66-9.57h28.37c.54,5.49,5.16,9.67,10.67,9.67s10.13-4.18,10.67-9.67h7.48c.76,0,1.49-.29,2.03-.83,.54-.53,.84-1.26,.84-2.02v-16.29c0-1.12-.64-2.14-1.66-2.6Zm-25.85-.58v-10.02h6.01l7.96,9.58-.44,.35-13.53,.08h0Zm-43.15,26.18c-2.03,.03-3.88-1.18-4.67-3.05-.8-1.87-.38-4.03,1.05-5.48,1.43-1.45,3.59-1.89,5.47-1.11,1.88,.77,3.11,2.6,3.11,4.63,0,2.74-2.2,4.97-4.95,5.01h0Zm49.71,0c-2.03,0-3.86-1.21-4.64-3.08-.78-1.87-.35-4.03,1.08-5.47,1.44-1.44,3.59-1.86,5.47-1.08,1.87,.78,3.09,2.61,3.08,4.64,0,1.33-.54,2.61-1.49,3.54-.95,.94-2.23,1.46-3.56,1.44h.06Z"
                fill="#003566"
              />
              <path
                d="M32.74,38.18c1.08,.09,2.12-.43,2.69-1.35s.57-2.09,0-3.01-1.61-1.45-2.69-1.35H10.13c-1.48,.13-2.62,1.37-2.62,2.86s1.14,2.73,2.62,2.86h22.61Z"
                fill="#003566"
              />
              <path
                d="M28.66,48.28c0-.76-.3-1.49-.84-2.02s-1.27-.83-2.03-.83H3.12c-1.08-.09-2.12,.43-2.69,1.35-.57,.92-.57,2.09,0,3.01s1.61,1.45,2.69,1.35H25.8c.76,0,1.49-.3,2.03-.84s.84-1.27,.84-2.03h-.01Z"
                fill="#003566"
              />
            </g>
          </svg>
        </div>
        <Typography variant="h2" fontSize="1.5rem" color="primary.dark" fontWeight="600">
          Delivery Options
        </Typography>
      </Link>
    </Grid>
    <Grid item xs={6} md={3}>
      <Link href="/business-plus" className="StoreFeature">
        <div className="StoreFeature__Icon">
          <svg viewBox="0 0 100 100" alt="" role="presentation" aria-hidden="true">
            <rect width="100" height="100" fill="none" />
            <g>
              <path
                d="M76.68,70.06c-.78,0-1.54-.27-2.14-.77l-15.11-12.6h-6.15c-7.09,0-13.89-2.82-18.91-7.83-5.01-5.01-7.83-11.82-7.83-18.91s2.82-13.89,7.83-18.91c5.02-5.01,11.82-7.83,18.91-7.83h20.05c6.5,.02,12.76,2.4,17.63,6.7,4.87,4.3,8,10.23,8.82,16.67,.82,6.44-.74,12.96-4.37,18.35-3.64,5.38-9.11,9.25-15.39,10.9v10.9c0,.64-.18,1.27-.52,1.8-.34,.54-.83,.97-1.41,1.24-.44,.2-.92,.3-1.4,.3h0ZM53.28,9.9c-5.32,0-10.42,2.11-14.18,5.87-3.76,3.76-5.87,8.86-5.87,14.18s2.11,10.42,5.87,14.18,8.86,5.87,14.18,5.87h7.35c.78,0,1.54,.27,2.14,.77l10.56,8.79v-6.48c0-.81,.28-1.6,.81-2.21s1.26-1.02,2.07-1.13c4.94-.81,9.4-3.44,12.5-7.37s4.62-8.88,4.26-13.87c-.36-4.99-2.57-9.67-6.21-13.11-3.63-3.44-8.42-5.4-13.43-5.5h-20.05Z"
                fill="#003566"
              />
              <path d="M66.65,26.61h-6.69v6.68h6.69v-6.68Z" fill="#003566" />
              <path d="M49.94,26.61h-6.68v6.68h6.68v-6.68Z" fill="#003566" />
              <path d="M83.36,26.61h-6.68v6.68h6.68v-6.68Z" fill="#003566" />
              <path
                d="M23.2,96.79c-.48,0-.96-.1-1.4-.3-.58-.27-1.07-.7-1.41-1.24s-.53-1.17-.52-1.8v-10.9c-5.32-1.42-10.07-4.44-13.61-8.66C2.71,69.69,.56,64.48,.1,59c-.47-5.48,.77-10.98,3.54-15.73,2.77-4.76,6.94-8.54,11.94-10.84,.4-.21,.85-.34,1.3-.38,.45-.04,.91,.02,1.34,.17s.83,.38,1.17,.69c.34,.31,.61,.68,.8,1.1,.19,.42,.29,.87,.29,1.32s-.08,.91-.25,1.33c-.17,.42-.43,.8-.76,1.12s-.72,.56-1.14,.73c-3.94,1.79-7.2,4.81-9.29,8.6-2.09,3.79-2.89,8.16-2.29,12.45,.6,4.29,2.58,8.27,5.63,11.34s7.02,5.08,11.3,5.71c.8,.11,1.54,.52,2.07,1.13,.53,.61,.82,1.4,.81,2.21v6.48l10.56-8.79c.58-.55,1.34-.87,2.14-.9h7.35c2.68,0,5.33-.53,7.79-1.58,2.46-1.05,4.69-2.58,6.55-4.51,.62-.64,1.47-1,2.36-1.02,.44,0,.88,.07,1.29,.24,.41,.16,.78,.4,1.1,.71s.57,.67,.74,1.08c.17,.4,.27,.84,.27,1.28s-.07,.88-.24,1.29c-.16,.41-.4,.78-.71,1.1-2.49,2.56-5.47,4.59-8.76,5.98-3.29,1.39-6.82,2.11-10.39,2.11h-6.15l-15.11,12.6c-.6,.5-1.36,.77-2.14,.77h0Z"
                fill="#003566"
              />
            </g>
          </svg>
        </div>
        <Typography variant="h2" fontSize="1.5rem" color="primary.dark" fontWeight="600">
          Design Consultation
        </Typography>
      </Link>
    </Grid>
    <Grid item xs={6} md={3}>
      <Link href="https://careers.roomstogo.com/" className="StoreFeature">
        <div className="StoreFeature__Icon">
          <svg viewBox="0 0 100 100.34" alt="" role="presentation" aria-hidden="true">
            <rect width="100" height="100" fill="none" />
            <path
              d="M50,32.7c6.58,0,11.96,5.38,11.96,11.96v5.55c0,1.38-1.12,2.51-2.51,2.51h-18.9c-1.38,0-2.51-1.12-2.51-2.51v-5.55c0-6.58,5.38-11.96,11.96-11.96h0Zm-22.12,31.17c-3.3,0-3.3-5.02,0-5.02h44.24c3.3,0,3.3,5.02,0,5.02H27.88Zm0,21.59c-3.3,0-3.3-5.02,0-5.02h44.24c3.3,0,3.3,5.02,0,5.02H27.88Zm0-10.79c-3.3,0-3.3-5.02,0-5.02h44.24c3.3,0,3.3,5.02,0,5.02H27.88ZM13.14,0h56.61c.69,0,1.32,.28,1.77,.74l17.11,17.11c.49,.49,.74,1.13,.74,1.77V97.83c0,1.38-1.12,2.51-2.51,2.51H13.14c-1.39,0-2.51-1.12-2.51-2.51V2.51c0-1.39,1.12-2.51,2.51-2.51h0Zm55.57,5.02H15.65V95.32H84.36V20.66l-15.65-15.65h0Zm-18.71,6.97c5.59,0,10.11,4.53,10.11,10.12s-4.53,10.11-10.11,10.11-10.11-4.53-10.11-10.11,4.53-10.12,10.11-10.12Zm0,5.02c-2.82,0-5.1,2.28-5.1,5.1s2.28,5.1,5.1,5.1,5.1-2.28,5.1-5.1-2.28-5.1-5.1-5.1Zm0,20.71c-3.81,0-6.95,3.13-6.95,6.95v3.05h13.89v-3.05c0-3.82-3.13-6.95-6.95-6.95h.01Z"
              fill="#003566"
            />
          </svg>
        </div>
        <Typography variant="h2" fontSize="1.5rem" color="primary.dark" fontWeight="600">
          Job Inquiries
        </Typography>
      </Link>
    </Grid>
  </StoreFeatureStyles>
)

export default StoreFeatures
