import React, { useState } from 'react'
import { string, array } from 'prop-types'
import { Box, styled, Typography } from '@mui/material'
import SwitchContainer from '../../../molecules/Stores/SwitchContainer/SwitchContainer'
import FlipContainer from '../../../molecules/Stores/FlipContainer/FlipContainer'

const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  height: '690px',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    height: 305,
  },
}))

const TitleBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(0, 53, 102, 1)',
  height: 89,
  display: 'flex',
  alignItems: 'center',
  padding: '0 16px',
  textTransform: 'uppercase',
  width: '100%',
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const TitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 700,
  fontSize: 40,
  lineHeight: '56.72px',
}))

const StyledFlipContainer = styled(FlipContainer)(({ theme }) => ({
  position: 'absolute',
  bottom: 20,
  right: 20,
  [theme.breakpoints.down('md')]: {
    bottom: 12,
    right: 12,
  },
  zIndex: 2,
}))

const Header = ({ title, switchItems, className }) => {
  const [currentDisplay, setCurrentDisplay] = useState(0)
  return (
    <Wrapper className={className}>
      <SwitchContainer currentDisplay={currentDisplay} switchItems={switchItems} />
      {switchItems?.length > 1 && (
        <StyledFlipContainer
          setCurrentDisplay={setCurrentDisplay}
          currentDisplay={currentDisplay + 1}
          switchItems={switchItems}
        />
      )}
      <TitleBox>
        <TitleText>{title}</TitleText>
      </TitleBox>
    </Wrapper>
  )
}

Header.propTypes = {
  title: string,
  switchItems: array,
  className: string,
}

export default Header
