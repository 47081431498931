import React from 'react'
import { object, string } from 'prop-types'
import StoreAddress from '@components/store-details-v1/store-address'
import StorePhone from '@components/store-details-v1/store-phone'
import StoreHoursToday from '@components/store-details-v1/store-hours-today'
import { LocationInformation } from '@components/store-details-v1/styles'

const StoreLocationInfo = ({ store, storeInfoFromFeed, className, children }) => (
  <LocationInformation className={className}>
    <StoreAddress storeInfoFromFeed={storeInfoFromFeed} store={store} />
    <StoreHoursToday storeInfoFromFeed={storeInfoFromFeed} />
    <StorePhone store={store} storeInfoFromFeed={storeInfoFromFeed} />
    {children}
  </LocationInformation>
)

StoreLocationInfo.propTypes = {
  store: object,
  storeInfoFromFeed: object,
  className: string,
  children: object,
}

export default StoreLocationInfo
