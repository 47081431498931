import React from 'react'
import styled, { keyframes } from 'styled-components'

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

export const Spinner = styled.div`
  border-left: 3px solid #0dab68;
  border-right: 3px solid #ee3944;
  border-top: 3px solid #fcd045;
  border-bottom: 3px solid #19539f;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spin} 0.6s linear infinite;
`

const LoadingSpinner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgray;
`

const Loader = () => (
  <LoadingSpinner>
    <Spinner />
  </LoadingSpinner>
)

export default Loader
