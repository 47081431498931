import React from 'react'
import { object } from 'prop-types'
import { findLastIndex, get } from 'lodash'
import { weekdays, getStandardTime } from '@helpers/string-helper'
import { SectionHeader, SectionWrapper, StoreHoursTimeWrapper } from '@components/store-details-v1/styles'

const getHours = (storeHours, day) => {
  const storeOpen = storeHours[`${day.toLowerCase()}Open`]
  const storeClose = storeHours[`${day.toLowerCase()}Closed`]
  if (storeOpen && storeClose) {
    return `${getStandardTime(storeOpen)} - ${getStandardTime(storeClose)}`
  }
  return 'Closed'
}

const StoreHours = ({ storeInfoFromFeed }) => {
  const storeHours = get(storeInfoFromFeed, 'store_hours')
  if (storeHours) {
    const condensedDays = []
    // As we go through the days of the week sequentially, we create a new array
    // containing sequential days that have the same store hours condensed into a single
    // entry (ie Monday-Friday: 8:00AM - 10:00 PM) by overwriting the name of the day.
    // Because we're only looking at the last index in the new array,
    // we prevent condensing any non-sequential days with the same hours.
    weekdays.map(day => {
      const hours = getHours(storeHours, day)
      const details = {
        day,
        hours,
      }
      const condensedDayIndex = findLastIndex(condensedDays, condensedDay => condensedDay.hours === hours)
      const condensedDayLastIndex = condensedDays.length - 1
      if (condensedDayLastIndex >= 0 && condensedDayLastIndex === condensedDayIndex) {
        const parts = condensedDays[condensedDayIndex].day.split('-')
        condensedDays[condensedDayIndex].day = `${parts[0]}-${day}`
      } else {
        condensedDays.push(details)
      }
      return null
    })

    return (
      <SectionWrapper>
        <SectionHeader>Store Hours:</SectionHeader>
        <StoreHoursTimeWrapper>
          <div>
            {condensedDays.map(day => (
              <span className="day" key={day.day}>
                {day.day}
              </span>
            ))}
          </div>
          <div>
            {condensedDays.map(day => (
              <span className="time" key={day.day}>
                {day.hours}
              </span>
            ))}
          </div>
        </StoreHoursTimeWrapper>
      </SectionWrapper>
    )
  }
  return null
}

StoreHours.propTypes = {
  storeInfoFromFeed: object,
}

export default StoreHours
