import styled from 'styled-components'
import { Map } from 'google-maps-react'

const StyledGoogleMap = styled(Map)`
  width: 100%;
  height: 20rem !important;
  position: relative !important;
  @media screen and (max-width: 768px) {
    height: 12rem !important;
  }
`

export default StyledGoogleMap
