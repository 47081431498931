import React from 'react'
import { array } from 'prop-types'
import { NeighboorhoodWrapper, SectionHeader } from '@components/store-details-v1/styles'

const StoreNeighborhoods = ({ neighborhoodsNearby }) => {
  if (neighborhoodsNearby && neighborhoodsNearby.length > 0) {
    return (
      <NeighboorhoodWrapper>
        <SectionHeader>Neighborhoods Nearby:</SectionHeader>
        <ul>
          {neighborhoodsNearby.map(({ Name: hood }) => (
            <li key={hood} className="hood">
              {hood}
            </li>
          ))}
        </ul>
      </NeighboorhoodWrapper>
    )
  }
  return null
}

StoreNeighborhoods.propTypes = {
  neighborhoodsNearby: array,
}

export default StoreNeighborhoods
