import React from 'react'
import { string } from 'prop-types'
import { StoreTitleWrapper, StoreTitleCityState, StoreTitleType } from './styles'

const StoreTitle = ({ storeTypeTitle }) => {
  if (storeTypeTitle) {
    // first capture group matches 'city, st' like 'New Orleans, LA',
    // second capture group matches store type like 'Furniture & Mattress Store'
    // and returns undefined if it doesn't exists
    const regExp = /(.+,\s\w{2})(.+)*/gi
    const matches = regExp.exec(storeTypeTitle.toUpperCase())
    if (!matches) {
      return null
    }
    const cityState = matches[1] ? matches[1] : ''
    const type = matches[2] ? matches[2] : ''

    return (
      <StoreTitleWrapper>
        <StoreTitleCityState>{`${cityState}`}</StoreTitleCityState>
        <StoreTitleType>{`${type}`}</StoreTitleType>
      </StoreTitleWrapper>
    )
  }
  return null
}

StoreTitle.propTypes = {
  storeTypeTitle: string,
}

export default StoreTitle
