import React from 'react'
import { object } from 'prop-types'
import { getStandardTime, months, weekdays } from '@helpers/string-helper'
import { Typography, styled, css } from '@mui/material'

const StoreHoursStyled = styled(Typography)(
  ({ theme, isOpen }) => css`
    color: ${theme.palette.success.main};
    font-weight: 600;

    ${!isOpen} {
      color: ${theme.palette.error.main};
    }
  `,
)

const getTodayOpen = store => {
  const currentDay = weekdays[new Date().getDay()]
  const month = months[new Date().getMonth()].substr(0, 3)
  const day = new Date().getDate()
  const storeOpen = store.store_hours[`${currentDay.toLowerCase()}Open`]
  const storeClose = store.store_hours[`${currentDay.toLowerCase()}Closed`]
  if (storeOpen && storeClose) {
    return (
      <StoreHoursStyled variant="body1" isOpen={storeOpen}>
        {storeOpen ? ` Open today ${getStandardTime(storeOpen)} - ${getStandardTime(storeClose)}` : 'Closed today'}
      </StoreHoursStyled>
    )
  }
  return `${currentDay}, ${month} ${day}`
}

const StoreHoursToday = ({ storeInfoFromFeed }) => {
  if (storeInfoFromFeed) {
    return storeInfoFromFeed ? getTodayOpen(storeInfoFromFeed) : ''
  }
  return null
}

StoreHoursToday.propTypes = {
  storeInfoFromFeed: object,
}

export default StoreHoursToday
