import React from 'react'
import { object } from 'prop-types'
import { SpecialClosingsWrapper } from '@components/store-details-v2/styles'
import { StaticQuery, graphql } from 'gatsby'
import Markdown from '@templates/strapi-cms/content-types/Markdown'

const getAllStoresSpecialMsg = () => (
  <StaticQuery
    query={graphql`
      query getAllStoresSpecialMsg {
        strapiMarkdown(Title: { eq: "_ALL Stores - Special Message" }) {
          ...StrapiPageMarkdownFragment
        }
      }
    `}
    render={data => {
      if (data && data.contentfulMarkdown) {
        return <Markdown data={data.contentfulMarkdown} />
      }
      return null
    }}
  />
)

const SpecialClosings = ({ specialClosings }) => {
  if (specialClosings?.Markdown?.Markdown?.data?.childMarkdownRemark) {
    return (
      <SpecialClosingsWrapper>
        <div>
          <span>Special store hours TODAY: </span>
          <span
            className="specialClosings"
            dangerouslySetInnerHTML={{ __html: specialClosings.Markdown.Markdown.data.childMarkdownRemark.html }}
          />
        </div>
      </SpecialClosingsWrapper>
    )
  }
  return <SpecialClosingsWrapper>{getAllStoresSpecialMsg()}</SpecialClosingsWrapper>
}

SpecialClosings.propTypes = {
  specialClosings: object,
}

export default SpecialClosings
