import React from 'react'
import { object, string } from 'prop-types'
import Helmet from 'react-helmet'
import { Seo } from './styles'

const makePageHeading = headingText => (
  <Seo>
    <h1>{headingText}</h1>
  </Seo>
)

const StoreSeo = ({ seo, storeName, storeTypeTitle }) => {
  if (seo) {
    return (
      <>
        {seo.PageTitle && <Helmet title={seo.PageTitle} />}
        {seo.Canonical && (
          <Helmet
            link={[
              {
                rel: 'canonical',
                href: seo.Canonical,
              },
            ]}
          />
        )}
        {seo.MetaDescription && (
          <Helmet
            meta={[
              {
                name: 'description',
                content: seo.MetaDescription,
              },
            ]}
          />
        )}
        {seo.MetaRobots && (
          <Helmet
            meta={[
              {
                name: 'robots',
                content: seo.MetaRobots,
              },
            ]}
          />
        )}
        {seo.LD_Schema && (
          <Helmet
            script={[
              {
                type: 'application/ld+json',
                innerHTML: JSON.stringify(seo.LD_Schema),
              },
            ]}
          />
        )}
      </>
    )
  }
  return null
}

StoreSeo.propTypes = {
  seo: object,
  storeName: string,
  storeTypeTitle: string,
}

export default StoreSeo
