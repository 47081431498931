import React from 'react'
import { object } from 'prop-types'
import { LocationInformationItemHeader, LocationInformationItemWrapper } from '@components/store-details-v1/styles'
import map from '@images/map.svg'
import RTGLink from '@components/shared/link'

const StoreAddress = ({ storeInfoFromFeed, store }) => {
  const { address1, address2, city, state, zip } = storeInfoFromFeed || {}
  const isAddressFromFeedValid = address1 && city && state && zip
  const addressFromFeed = `${address1}${address2 ? `, ${address2}` : ''}, ${city}, ${state} ${zip}`
  const latitude = storeInfoFromFeed?.latitude || store?.Latitude || null
  const longitude = storeInfoFromFeed?.longitude || store?.Longitude || null
  const storeName = storeInfoFromFeed?.storeName || store?.Name || null
  return (
    <LocationInformationItemWrapper>
      <img alt="pin" src={map} />
      <div>
        <LocationInformationItemHeader>ADDRESS: </LocationInformationItemHeader>
        <RTGLink
          data={{
            url: `https://www.google.com/maps/dir/?api=1&origin=Current+Location&destination=${latitude},${longitude}`,
            title: `Directions to ${storeName}`,
            category: 'store locator',
            action: 'Directions store map click',
            label: 'Directions to store',
          }}
          target="_blank"
          className="store-address-link"
        >
          {isAddressFromFeedValid ? addressFromFeed : store.Address1}
        </RTGLink>
      </div>
    </LocationInformationItemWrapper>
  )
}

StoreAddress.propTypes = {
  store: object,
  storeInfoFromFeed: object,
}

export default StoreAddress
