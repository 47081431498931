import React from 'react'
import { object } from 'prop-types'
import getStorePhone from '@helpers/getStorePhone'
import RTGLink from '@components/shared/link'
import { LocationInformationItemHeader, LocationInformationItemWrapper } from '@components/store-details-v1/styles'
import tel from '@images/tel.svg'

const StorePhone = ({ store, storeInfoFromFeed }) => (
  <LocationInformationItemWrapper>
    <img alt="telephone" src={tel} />
    <div>
      <LocationInformationItemHeader>PHONE: </LocationInformationItemHeader>
      <RTGLink
        data={{
          url: `tel:${getStorePhone(storeInfoFromFeed, store)}`,
          title: 'Call store',
          category: 'store page',
          action: 'Call click',
          label: 'Call',
        }}
        className="phone-link"
      >
        {getStorePhone(storeInfoFromFeed, store)}
      </RTGLink>
    </div>
  </LocationInformationItemWrapper>
)

StorePhone.propTypes = {
  store: object,
  storeInfoFromFeed: object,
}

export default StorePhone
