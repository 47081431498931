import styled from 'styled-components'
import { breakPoints } from '@constants/styles'

export const Seo = styled.section`
  background-color: #0053a0;
  color: #000000;
  margin: 3px 0 0 0;
  > h1 {
    padding-left: 10px;
  }
  @media screen and (max-width: ${breakPoints['medium-max']}) {
    margin: 13px 0 0 0;
    > h1 {
      padding-left: 0px;
    }
  }
`

export const SectionHeader = styled.h4`
  font-weight: 700;
  margin: 3px 0;
  @media screen and (max-width: ${breakPoints['medium-max']}) {
    margin: 5px 0;
  }
`

export const SectionWrapper = styled.div`
  margin: 0 0 20px 0;
`

export const StoreDetailsWrapper = styled.div`
  background-color: #ffffff;
  margin: 0;
`
export const StoreTitleWrapper = styled.div`
  background-color: #002139;
  color: #ffffff;
  display: flex;
  align-items: baseline;
  padding: 7px 0;
  width: 100%;
  > div {
    margin-left: 20px;
  }
  @media screen and (max-width: ${breakPoints['medium-max']}) {
    flex-direction: column;
    margin: 10px 0;
    padding: 12px 0;
  }
`

export const StoreTitleCityState = styled.div`
  background-color: #002139;
  color: #ffffff;
  font-size: 32px;
  font-weight: 700;
  @media screen and (max-width: ${breakPoints['medium-max']}) {
    font-size: 24px;
  }
`

export const StoreTitleType = styled.div`
  background-color: #002139;
  color: #ffffff;
  font-size: 26px;
  font-weight: 300;
  @media screen and (max-width: ${breakPoints['medium-max']}) {
    font-size: 24px;
  }
`
export const StorePhone = styled.div`
  margin-left: 20px;
  > a {
    color: #0053a0;
  }
  @media screen and (max-width: ${breakPoints['medium-max']}) {
    margin-left: 0;
  }
`
export const StoreDetailsPageColumn1 = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-right: 20px;
  min-width: 33%;
  @media screen and (max-width: ${breakPoints['medium-max']}) {
    min-width: 100%;
    padding: 0 30px;
  }
`

export const StoreDetailsPageColumn2 = styled.div`
  display: flex;
  flex-flow: column nowrap;
  min-width: 67%;
  @media screen and (max-width: ${breakPoints['medium-max']}) {
    min-width: 100%;
  }
`

export const StoreDetailsPageColumnWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 25px;
  width: 100%;
`

export const StoreHoursTimeWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  > div > span {
    margin: 5px 0;
    display: flex;
    flex-flow: column nowrap;
    margin-right: 50px;
  }
  @media screen and (max-width: ${breakPoints['medium-max']}) {
    justify-content: space-between;
    > div > span {
      margin: 5px 0;
      display: flex;
      flex-flow: column nowrap;
    }
  }
`

export const NeighboorhoodWrapper = styled(SectionWrapper)`
  ul {
    column-count: 2;
  }
  li {
    margin: 3px 0;
  }
  @media screen and (max-width: ${breakPoints['medium-max']}) {
    ul {
      column-count: 1;
    }
    li {
      margin: 5px 0;
    }
  }
`

export const FaqWrapper = styled(SectionWrapper)`
  h3 {
    font-weight: 700;
    margin: 3px 0;
  }
  ul {
    list-style: disc;
    margin-left: 20px;
  }
  a {
    color: #0053a0;
  }
`

export const StyledMapsWrapper = styled(SectionWrapper)`
  div > .map {
    max-width: 1036px !important;
    padding-bottom: 62.5%;
  }
  aspect-ratio: 16 / 10;
  @media screen and (max-width: ${breakPoints['medium-max']}) {
    width: 100%;
    min-height: 12rem;
    div > .map {
      max-width: 1036px !important;
      height: 12rem !important;
      padding-bottom: 0;
    }
  }
`

export const LocationInformation = styled(SectionWrapper)`
  display: flex;
  flex-direction: column;
`

export const LocationInformationItemWrapper = styled.span`
  display: flex;
  margin-bottom: 10px;
  div > span > span {
    font-weight: 700;
  }
  div > span > span:nth-child(1) {
    color: #02864d;
  }
  a {
    color: #0053a0;
  }
  img {
    height: 25px;
    margin-right: 10px;
  }
  @media screen and (max-width: ${breakPoints['medium-max']}) {
    margin-bottom: 15px;
  }
`

export const LocationInformationItemHeader = styled.b`
  font-weight: 700;
  margin: 0 10px 10px 0;
  @media screen and (max-width: ${breakPoints['medium-max']}) {
    margin: 0;
  }
`

export const InStoreAppointmentWrapper = styled(SectionWrapper)`
  display: flex;
  flex-direction: column;
  text-align: center;
  @media screen and (min-width: ${breakPoints['large-min']}) {
    flex-direction: row;
  }
  > span {
    border: 1px solid #0053a0;
    color: #0053a0;
    cursor: pointer;
    font-weight: bold;
    padding: 8px 16px;
    text-decoration: none;
  }
`

export const StoreDetailsImage = styled.img`
  width: 100%;
  max-width: 504px;
`

export const SpecialClosingsWrapper = styled.div`
  > * {
    @media screen and (max-width: ${breakPoints['medium-max']}) {
      flex-direction: column;
      margin: 0px 0px 10px 0px;
    }
    background-color: #dfffe4;
    border: 1px solid #008652;
    color: #008652;
    display: flex;
    margin: 0px 0px 20px 0px;
    padding: 10px;
    p {
      color: #008652;
      font-weight: 700;
    }
    span {
      margin-right: 5px;
    }
  }
`
