import React from 'react'
import { object } from 'prop-types'
import { Stack, Typography, styled, css } from '@mui/material'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import RTGLink from '@components/shared/link'

const StyledAddressText = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.primary.main};
    margin-left: 13px;
    &:hover {
      text-decoration-line: underline;
    }
  `,
)

const StoreAddress = ({ storeInfoFromFeed, store }) => {
  const address1 = storeInfoFromFeed?.address1 || store?.Address1 || null
  const address2 = storeInfoFromFeed?.address2 || store?.Address2 || null
  const city = storeInfoFromFeed?.city || store?.City || null
  const state = storeInfoFromFeed?.state || store?.State || null
  const zip = storeInfoFromFeed?.zip || store?.Zip || null
  const isAddressValid = address1 && city && state && zip
  const fullAddress = `${address1}${address2 ? `, ${address2}` : ''}`
  const latitude = storeInfoFromFeed?.latitude || store?.Latitude || null
  const longitude = storeInfoFromFeed?.longitude || store?.Longitude || null
  const storeName = storeInfoFromFeed?.storeName || store?.Name || null
  return (
    <Stack direction="row">
      <LocationOnOutlinedIcon color="primary" />
      <RTGLink
        data={{
          url: `https://www.google.com/maps/dir/?api=1&origin=Current+Location&destination=${latitude},${longitude}`,
          title: `Directions to ${storeName}`,
          category: 'store locator',
          action: 'Directions store map click',
          label: 'Directions to store',
        }}
        target="_blank"
        className="store-address-link"
      >
        <StyledAddressText variant="body1">
          {isAddressValid && fullAddress}
          {isAddressValid && <br />}
          {isAddressValid && `${city}, ${state} ${zip}`}
        </StyledAddressText>
      </RTGLink>
    </Stack>
  )
}

StoreAddress.propTypes = {
  store: object,
  storeInfoFromFeed: object,
}

export default StoreAddress
